import React, { useState } from "react";
import { MetaDataProvider } from "./component/MetaDataContext"; // Ensure the correct path
import Navbar from "./component/Navbar"; // Import the Navbar component
import WalletUTXOs from "./component/WalletUTXOs";
import ARView from "./component/ARView";
import './App.css';

function App() {
    const [wallet, setWallet] = useState(null);
    const [arAsset, setArAsset] = useState(null);
    const [setSelectedAsset] = useState(null);
    const [transactionType] = useState("ada");
    const [adaHandle, setAdaHandle] = useState(null);

    const updateAdaHandle = (handle) => {
        setAdaHandle(handle);
    };

    // Handle AR View action
    const handleArView = (asset) => {
        console.log("Selected Asset for AR View:", asset);
        setArAsset(asset); // Set the asset to be viewed in AR
    };

    // Handle Select Asset action
    const handleSelectAsset = (asset) => {
        console.log("Selected Asset for Token Operations:", asset);
        setSelectedAsset(asset); // Set the selected asset for further actions
    };

    return (
        <MetaDataProvider>
            <div className="App">
                {/* Navbar with Connect Wallet */}
                <Navbar setWallet={setWallet} adaHandle={adaHandle} />

                <header className="App-header">
                    {/* Wallet UTXOs Component to display NFTs and set selected asset */}
                    {wallet && (
                        <WalletUTXOs
                            wallet={wallet}
                            handleArView={handleArView} // Pass AR view handler
                            handleSelectAsset={handleSelectAsset}
                            onUpdateAdaHandle={updateAdaHandle} // Pass asset selection handler
                            adaHandle={adaHandle}
                            setWallet={setWallet}
                        />
                    )}

                    {/* AR View Component to render the selected asset in AR if one is selected */}
                    {arAsset && (
                        <div style={{ marginTop: '20px' }}>
                            <h3>AR View</h3>
                            <ARView asset={arAsset} />
                        </div>
                    )}
                </header>
            </div>
        </MetaDataProvider>
    );
}

export default App;
