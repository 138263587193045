import React, { useState } from "react";
import {
    Address,
    BigNum,
    CoinSelectionStrategyCIP2,
    LinearFee,
    Transaction,
    TransactionBuilder,
    TransactionBuilderConfigBuilder,
    TransactionOutput,
    TransactionUnspentOutput,
    TransactionUnspentOutputs,
    TransactionWitnessSet,
    Value,
} from "@emurgo/cardano-serialization-lib-asmjs";

function WalletAdaTx({ wallet }) {
    const [toAddress, setToAddress] = useState("");
    const [lovelaces, setLovelaces] = useState("");
    const [txHash, setTxHash] = useState(null);
    const [error, setError] = useState(null);

    const protocolParams = {
        linearFee: {
            minFeeA: "44",
            minFeeB: "155381",
        },
        poolDeposit: "500000000",
        keyDeposit: "2000000",
        coinsPerUtxoWord: "34482",
        maxValSize: 5000,
        maxTxSize: 16384,
    };

    // Initialize the transaction builder with protocol parameters
    const initTransactionBuilder = async () => {
        const txBuilder = TransactionBuilder.new(
            TransactionBuilderConfigBuilder.new()
                .fee_algo(LinearFee.new(BigNum.from_str(protocolParams.linearFee.minFeeA), BigNum.from_str(protocolParams.linearFee.minFeeB)))
                .pool_deposit(BigNum.from_str(protocolParams.poolDeposit))
                .key_deposit(BigNum.from_str(protocolParams.keyDeposit))
                .coins_per_utxo_word(BigNum.from_str(protocolParams.coinsPerUtxoWord))
                .max_value_size(protocolParams.maxValSize)
                .max_tx_size(protocolParams.maxTxSize)
                .prefer_pure_change(true)
                .build()
        );

        return txBuilder;
    };

    // Fetch UTXOs from the wallet
    const getTxUnspentOutputs = async () => {
        let txOutputs = TransactionUnspentOutputs.new();
        const utxos = await wallet.api.getUtxos();
        for (const utxo of utxos) {
            const txUnspentOutput = TransactionUnspentOutput.from_hex(utxo);
            txOutputs.add(txUnspentOutput);
        }
        return txOutputs;
    };

    const handleSendTransaction = async () => {
        try {
            setError(null);
            console.log("=== STARTING TRANSACTION PROCESS ===");

            // Step 1: Initialize Transaction Builder
            const txBuilder = await initTransactionBuilder();

            // Step 2: Add Output (Recipient Address and Amount)
            const shelleyOutputAddress = Address.from_bech32(toAddress);
            const value = Value.new(BigNum.from_str(lovelaces));
            txBuilder.add_output(TransactionOutput.new(shelleyOutputAddress, value));
            console.log(`Output added: ${lovelaces} lovelaces to address ${toAddress}`);

            // Step 3: Fetch UTXOs and Add Inputs
            const txUnspentOutputs = await getTxUnspentOutputs();
            txBuilder.add_inputs_from(txUnspentOutputs, CoinSelectionStrategyCIP2.RandomImprove);
            console.log("UTXOs added successfully.");

            // Step 4: Set Change Address
            const changeAddress = Address.from_bech32(wallet.bech32);
            txBuilder.add_change_if_needed(changeAddress);
            console.log("Change address set to:", wallet.bech32);

            // Step 5: Build Transaction
            const txBody = txBuilder.build();
            console.log("Transaction body:", txBody.to_hex());

            // Step 6: Create Unsigned Transaction
            const noWitnessSet = TransactionWitnessSet.new();
            const txUnsigned = Transaction.new(txBody, noWitnessSet);
            console.log("Unsigned transaction (hex):", txUnsigned.to_hex());

            // Step 7: Sign Transaction
            const txRaw = txUnsigned.to_hex();
            const signedTx = await wallet.api.signTx(txRaw);
            console.log("Signed transaction hex:", signedTx);

            // Step 8: Attach Witnesses and Finalize Transaction
            const txWitness = TransactionWitnessSet.from_hex(signedTx);
            const txSigned = Transaction.new(txBody, txWitness);
            console.log("Final signed transaction:", txSigned.to_hex());

            // Step 9: Submit Transaction
            const submittedTxHash = await wallet.api.submitTx(txSigned.to_hex());
            console.log("Transaction submitted successfully. TxHash:", submittedTxHash);

            setTxHash(submittedTxHash);
            console.log("=== TRANSACTION PROCESS COMPLETED SUCCESSFULLY ===");
        } catch (err) {
            console.error("Error during transaction process:", err);
            setError(err.message);
        }
    };

    return (
        <div className="p-4 rounded-lg bg-dark text-white shadow-lg">
            <div className="mb-3">
                <label htmlFor="toAddress" className="form-label">
                    Recipient Address
                </label>
                <input
                    type="text"
                    className="form-control bg-dark text-white border-0 shadow-sm"
                    id="toAddress"
                    placeholder="Enter recipient wallet address"
                    value={toAddress}
                    onChange={(e) => setToAddress(e.target.value)}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="lovelaces" className="form-label">
                    Amount (in Lovelaces)
                </label>
                <input
                    type="number"
                    className="form-control bg-dark text-white border-0 shadow-sm"
                    id="lovelaces"
                    placeholder="Enter amount in lovelaces (1 ADA = 1,000,000 Lovelaces)"
                    value={lovelaces}
                    onChange={(e) => setLovelaces(e.target.value)}
                    min="1000000"
                />
            </div>
            <button
                className="btn btn-primary w-100"
                onClick={handleSendTransaction}
            >
                Send ADA
            </button>
            {txHash && (
                <div className="alert alert-success mt-3">
                    <strong>Transaction Successful!</strong> <br />
                    TxHash: <span className="text-break">{txHash}</span>
                </div>
            )}
            {error && (
                <div className="alert alert-danger mt-3">
                    <strong>Error:</strong> {error}
                </div>
            )}
        </div>
    );
}

export default WalletAdaTx;
