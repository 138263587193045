import React from 'react';
import ConnectWallet from './ConnectWallet';  // Import the ConnectWallet component
import pcsbanner from '../Images/pcsbanner(1).jpg'; // Import the PCS banner image

const Navbar = ({ setWallet, adaHandle }) => {
    return (
        <div className="navbar-container">
            {/* Banner Image using <img> */}
            <div className="banner" style={{ textAlign: 'center' }}>
                <img
                    src={pcsbanner}
                    alt="PCS Banner"
                    style={{
                        width: '100%',  // Make the image span the full width
                        height: '250px',  // Adjust the height of the banner
                        objectFit: 'cover',  // Ensure the image covers the area properly
                    }}
                />
            </div>

            {/* Navbar with Connect Wallet */}
            <nav className="navbar">
                {/* ConnectWallet component directly in the navbar */}
                <ConnectWallet
                    setWallet={setWallet}
                    adaHandle={adaHandle}
                />
            </nav>
        </div>
    );
};

export default Navbar;
