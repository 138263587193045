import React, { useEffect, useState } from "react";
import "aframe";

function ARView({ asset }) {
    const [assetPosition, setAssetPosition] = useState({ x: 0, y: 1.5, z: -3 });
    const [assetRotation, setAssetRotation] = useState({ x: 0, y: 0, z: 0 });
    const [showMetadata, setShowMetadata] = useState(true);

    useEffect(() => {
        if (!asset) return;

        // Request camera access
        navigator.mediaDevices
            .getUserMedia({ video: true })
            .then((stream) => {
                console.log("Camera access granted", stream);

                // Attach the stream to the A-Frame camera
                const video = document.createElement("video");
                video.srcObject = stream;
                video.play();
                video.style.position = "absolute";
                video.style.top = "0";
                video.style.left = "0";
                video.style.width = "100%";
                video.style.height = "100%";
                video.style.zIndex = "-1"; // Put it behind the AR content
                document.body.appendChild(video);
            })
            .catch((err) => {
                console.error("Camera access denied", err);
            });

        console.log("Rendering asset in AR:", asset);
    }, [asset]);

    const handleAssetClick = () => {
        // Move and rotate the asset on click
        setAssetPosition((prev) => ({
            x: prev.x + 0.5,
            y: prev.y,
            z: prev.z,
        }));
        setAssetRotation((prev) => ({
            x: prev.x,
            y: prev.y + 45,
            z: prev.z,
        }));
    };

    return (
        <div className="ar-view-container">
            {asset ? (
                <div className="ar-view-wrapper">
                    <a-scene
                        embedded
                        arjs="sourceType: webcam; debugUIEnabled: false; trackingMethod: best;"
                        style={{ width: "100%", height: "100%" }}
                    >
                        {/* AR Camera */}
                        <a-camera look-controls="enabled: true" position="0 1.6 0"></a-camera>

                        {/* 3D Asset */}
                        <a-image
                            src={asset.metadata?.image}
                            position={`${assetPosition.x} ${assetPosition.y} ${assetPosition.z}`}
                            scale="2 2 2"
                            rotation={`${assetRotation.x} ${assetRotation.y} ${assetRotation.z}`}
                            animation__rotate="property: rotation; to: 0 360 0; loop: true; dur: 10000"
                            onClick={handleAssetClick}
                        ></a-image>

                        {/* Metadata Panel */}
                        {showMetadata && (
                            <a-entity position="0 -1 -3">
                                <a-plane
                                    width="3"
                                    height="1.5"
                                    color="#000000"
                                    opacity="0.7"
                                    position="0 0 0"
                                    rotation="0 0 0"
                                >
                                    <a-text
                                        value={`Name: ${asset.metadata.name}\nCollection: ${asset.metadata.collection}\nBackground: ${asset.metadata.background}\nEyes: ${asset.metadata.eyes}\nFur: ${asset.metadata.fur}\nOutfit: ${asset.metadata.outfit}`}
                                        color="#FFFFFF"
                                        align="center"
                                        position="0 0.5 0"
                                        scale="1.5 1.5 1.5"
                                    ></a-text>
                                </a-plane>
                            </a-entity>
                        )}

                        {/* Toggle Metadata Button */}
                        <a-entity position="0 -2 -3">
                            <a-box
                                position="0 0 0"
                                width="0.5"
                                height="0.5"
                                depth="0.5"
                                color="#4CAF50"
                                onClick={() => setShowMetadata((prev) => !prev)}
                                animation__hover="property: scale; to: 0.6 0.6 0.6; startEvents: mouseenter; dur: 200"
                                animation__mouseleave="property: scale; to: 0.5 0.5 0.5; startEvents: mouseleave; dur: 200"
                            ></a-box>
                            <a-text
                                value={showMetadata ? "Hide Info" : "Show Info"}
                                color="#FFFFFF"
                                align="center"
                                position="0 -0.7 0"
                                scale="1.2 1.2 1.2"
                            ></a-text>
                        </a-entity>
                    </a-scene>
                </div>
            ) : (
                <p className="ar-placeholder-text">Please select an asset to view it in AR</p>
            )}
        </div>
    );
}

export default ARView;
